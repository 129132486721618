const largeImageSection =[
  {
    "large-image-section":{
    "largeImage":{
      "imageUrl":
        'AppImages/2222.png'
      ,
      'imageName':{}
    }
  }
  }
]

const dualImageSection =[
  {
    "dual-image-section":{
      "image1":{
        "imageUrl":{

        },
        "imageName":{

        }
      },
      "image2":{
        "imageUrl":{

        },
        "imageName":{

        }
      }
    }
  }
]

const dualDescSection =[
  {
    "dual-desc-section":{
      "desc1":{
        
      },
      "desc2":{
        
      }
    }
  }
]


const projectsData = [
  {
    "title": "ESOTERIC ART STUDIO",
    "WebsiteLink":'https://the.esoteric.gallery',
    "Instagram":'https://www.instagram.com/eesotericartstudio/',
    "description": {
      "short": "Biggest Personal Project",
      "longCol1": "Esoteric Art Studio, a self directed multimedia design & development project situated between fashion, form & function.",
      "longCol2": "The studio aims to deliver high quality, low quantity products, focused on sustainable physical & digital experience."
    },
    "contribution":"(Personal Project)",
    "techUsed": "JS, CSS, PHP, STRIPE, GSAP, P5.JS, API, LOCAL STORAGE, CHECKOUT, TRANSITIONS",
    "status": "Ongoing",
    "links": "Read More",
    "mobileModel":"/AppImages/ProjectImages/Esoteric/EsotericModel.webp",
    "readMoreData": [
    {"large-image-section": {
      "largeImage": {
        "imageUrl": "/AppImages/ProjectImages/Esoteric/gallerytransition3.webp",
        "imageName": "2222"
      }
    }},{"dual-image-section": {
      "image1": {
        "imageUrl": "/AppImages/ProjectImages/Esoteric/EsotericDual2.webm",
        "imageName": "EsotericMovie1"
      },
      "image2": {
        "imageUrl": "/AppImages/ProjectImages/Esoteric/EsotericDual1.webm",
        "imageName": "EsotericMovie2"
      }
    }},
    {"dual-desc-section": {
      "desc1": "The studio connects customer to commerce with an intuitive online shop platform where they can purchase & peruse my products & designs. The website has been completely custom coded & accopanying e-commerce with experimental design & interactive experience.",
      "desc2": "Overall, The Studio utilisies editorial direction & enduring elements of culture to deliver unisex designs through modern media. \n The focus on the intially released concept, campaign & brand is to explore the duality of gender. Through unisex products, people & philosphies."
    }},
    {"large-image-section": {
      "largeImage": {
        "imageUrl": "/AppImages/ProjectImages/Esoteric/gallerytransition7.webp",
        "imageName": "2222"
      }
    }},{"dual-image-section": {
      "image1": {
        "imageUrl": "/AppImages/ProjectImages/Esoteric/EsotericDual3.webm",
        "imageName": "2222"
      },
      "image2": {
        "imageUrl": "/AppImages/ProjectImages/Esoteric/EsotericDual4.jpg",
        "imageName": "2222"
      }
    }},
    {"dual-image-section": {
      "image1": {
        "imageUrl": "/AppImages/ProjectImages/Esoteric/EsotericDual5.jpg",
        "imageName": "2222"
      },
      "image2": {
        "imageUrl": "/AppImages/ProjectImages/Esoteric/EsotericDual6.webm",
        "imageName": "2222"
      }
    }},
    {"dual-image-section": {
      "image1": {
        "imageUrl": "/AppImages/ProjectImages/Esoteric/EsotericDual7.jpg",
        "imageName": "2222"
      },
      "image2": {
        "imageUrl": "/AppImages/ProjectImages/Esoteric/EsotericDual11.webm",
        "imageName": "2222"
      }
    }},
    {"dual-image-section": {
      "image1": {
        "imageUrl": "/AppImages/ProjectImages/Esoteric/EsotericDual9.webm",
        "imageName": "2222"
      },
      "image2": {
        "imageUrl": "/AppImages/ProjectImages/Esoteric/EsotericDual10.webm",
        "imageName": "2222"
      }
    }},
    ]
  },
 {
   "title": "MAX DONA",
   "WebsiteLink":'https://maxdona.com.au',
   "description": {
     "short": "Web Dev & Design",
     "longCol1": "Max Dona, a web based cinematography portfolio. Accompanied by interaction, beautiful visuals & a custom Content Management System.",
     "longCol2": "The goal driving Max's website: Create the best website I can. Through hours invested, design's tested & care for every step of the process."
   },
   "contribution":"(In Development)",
   "announcement":"Pre-production Desktop Demo",

   "techUsed": "REACT, FIREBASE, ANIME.JS, GSAP, REST API, LOCAL STORAGE & ADMIN LOGIN",
   "status": "Coming Soon",
   "links": "Read More",
   "mobileModel":"/AppImages/ProjectImages/MaxDona/MaxModel.webp",
   "readMoreData": [
    {"large-image-section": {
      "largeImage": {
        "imageUrl": "/AppImages/ProjectImages/MaxDona/MacsLarge1.png",
        "imageName": "Max Large 1"
      }
    }},
    
     {"dual-desc-section": {
       "desc1": "Developed over a year using React & Firebase, this interactive platform showcases max's artistry through videography, photography, & a custom content management system. It sets itself apart with interaction & animation reflecting Max's goal of an exceptional personal portfolio.",
       "desc2": "Emphasizing function, motion, usability, & responsiveness, the website ensures easy exploration of Max's work. It provides a memorable browsing experience, presenting his portfolio in a captivating manner that embodies his energy & creativity."
     }},
     {"large-image-section": {
       "largeImage": {
         "imageUrl": "/AppImages/ProjectImages/MaxDona/MacsLarge2.png",
         "imageName": "Max Large 2"
       }
     }},
     {"dual-image-section": {
  
       "image1": {
         "imageUrl": "/AppImages/ProjectImages/MaxDona/MacsDual4.webm",
         "imageName": "Max Dual 4"
       },
       "image2": {
         "imageUrl": "/AppImages/ProjectImages/MaxDona/MacsDual2.png",
         "imageName": "Max Dual 2",
         'id':'contain'

       }
     }},
     {"dual-image-section": {
       "image1": {
         "imageUrl": "/AppImages/ProjectImages/MaxDona/MacsDual3.webm",
         "imageName": "Max Dual 3"
       },
      "image2": {
        "imageUrl": "/AppImages/ProjectImages/MaxDona/MacsDual1.png",
        "imageName": "Max Dual 1"
      },
     }},
    ]
 }
,  
  {
    "title": "INKA",
    "WebsiteLink":'https://inka.com.au',
    "description": {
      "short": "Interaction Design",
      "longCol1": "Inka, fusing Japanese & Peruvian culture with motion & interaction. Connecting customers to cuisine within a web based application.",
      "longCol2": "This project focused on connecting the bold & beautiful nature of tradition with the intimacy & romance of the present."
    },
    'status':'Motion Design',
    "contribution":"(During Employment)",
    "techUsed": "SVG, LAZY LOADING, SCROLL ANIMATIONS, ILLUSTRATOR, CONTACT API, IFrame Config & PHOTOSHOP",
    "links": "Read More",
    "mobileModel":"/AppImages/ProjectImages/Inka/InkaModel.webp",
    "readMoreData": [
    {"large-image-section": {
      "largeImage": {
        "imageUrl": "/AppImages/ProjectImages/Inka/InkaLarge1.webm",
        "imageName": "2222"
      }
    }},
    {"dual-image-section": {
      "image1": {
        "imageUrl": "/AppImages/ProjectImages/Inka/InkaDual1.png",
        "imageName": "Inka Dual 1"
      },
      "image2": {
         "imageUrl": "/AppImages/ProjectImages/Inka/InkaDual2.webm",
         "imageName": "Inka Dual 2"
      }
    }},
    {"dual-desc-section": {
      "desc1": "This thoughtfully crafted website offers a streamlined experience, facilitating bookings through a user-friendly third-party system. Its functionality extends beyond mere reservations, encompassing essential features such as a comprehensive menu display, a visually captivating gallery, & crucial contact information.",
      "desc2": "As users navigate through the virtual experience, they are greeted with an intuitive interface that efficiently guides them through the booking process. Inka embodies a sophisticated yet accessible approach to connecting patrons with a vibrant culinary experience with its user-centric design & array of interactive features."
    }},

    {"dual-image-section": {
      "image1": {
        "imageUrl": "/AppImages/ProjectImages/Inka/InkaDual3.png",
        "imageName": "Inka Dual 3"
      },
      "image2": {
         "imageUrl": "/AppImages/ProjectImages/Inka/InkaDual4.png",
         "imageName": "Inka Dual 4"
      }
    }},
  ]
  },
  {
    "title": "ANALYSE MY PROPERTY",
    "WebsiteLink":'https://analysemyproperty.au',
    "contribution":"(During Employment)",
    "index":'amp',
    "description": {
      "short": "Web Design",
      "longCol1": "Analyse my property, communicating usability & sophistication of property analysis, through branding & a web based e-commerce package system.",
      "longCol2": "This project encompassed logo & branding, from packaging to packages, cards & website. Communicated through a consistently clear art direction."
    },
    'status':'Branding',
    "techUsed": "JS, CSS, Wordpress, STRIPE, GSAP, InDesign, Digital and Print PDF & Design Systems",
    "links": "Read More",
    "mobileModel":"/AppImages/ProjectImages/AMP/ampModel.webp",
    "readMoreData": [
     {"large-image-section": {
       "largeImage": {
         "imageUrl": "/AppImages/ProjectImages/AMP/AmpLarge1.webm",
         "imageName": "2222"
       }
     }},
     {"dual-image-section": {
       "image1": {
         "imageUrl": "/AppImages/ProjectImages/AMP/AmpDual1.png",
         "imageName": "Amp Dual 1"
       },
       "image2": {
          "imageUrl": "/AppImages/ProjectImages/AMP/AmpDual2.webm",
          "imageName": "Amp Dual 2"
       }
     }},
     {"dual-desc-section": {
       "desc1": "The project focuses on a web-based e-commerce system with a structured sitemap, guiding users through the property analysis process. The primary objective is to offer three analysis packages, with WooCommerce facilitating the purchase of these virtual products.",
       "desc2": "The project scope extends beyond the functional aspects to include branding elements such as business cards & logos. Additionally, the design of the three in-depth package documents adheres to a strong, minimalistic aesthetic, further enhancing the project's overall sophistication."
     }},
     {"large-image-section": {
       "largeImage": {
         "imageUrl": "/AppImages/ProjectImages/AMP/AmpLarge2.png",
         "imageName": "2222"
       }
     }},
    {"dual-image-section": {
      "image1": {
        "imageUrl": "/AppImages/ProjectImages/AMP/AmpDual3.png",
        "imageName": "Amp Dual 1"
      },
      "image2": {
         "imageUrl": "/AppImages/ProjectImages/AMP/AmpDual4.png",
         "imageName": "Amp Dual 2"
      }
    }},
    {"dual-image-section": {
      "image1": {
        "imageUrl": "/AppImages/ProjectImages/AMP/AmpDual5.png",
        "imageName": "Amp Dual 5"
      },
      "image2": {
         "imageUrl": "/AppImages/ProjectImages/AMP/AmpDual6.png",
         "imageName": "Amp Dual 6"
      }
    }},
   
    ]
  },
  {
    "title": "KAHIL FOODS",
    "contribution":"(During Employment)",
    "description": {
      "short": "E-Commerce",
      "longCol1": `Kahil Foods, a large scale comprehensive e-commerce system. Managing & maintaining over 100 products, product data & stock.`,
      "longCol2": `Embracing & communicating 50 years of award winning history & heritage illustrated within the website through quality content & imagery.`
    },
    'status':'Web Design',
    "techUsed": "WORDPRESS, WOO-COMMERCE, STRIPE, AFTERPAY CSV, JS, CSS & JQuery",
    "links": "Read More",
    "mobileModel":"/AppImages/ProjectImages/KahilMeats/KahilModel.webp",
    "readMoreData": [
    
     {"dual-image-section": {
       "image1": {
         "imageUrl": "/AppImages/ProjectImages/KahilMeats/KahilDual1.webm",
         "imageName": "Amp Dual 1"
       },
       "image2": {
          "imageUrl": "/AppImages/ProjectImages/KahilMeats/KahilDual2.png",
          "imageName": "Amp Dual 2"
       }
     }},
     {"large-image-section": {
       "largeImage": {
         "imageUrl": "/AppImages/ProjectImages/KahilMeats/KahilLarge1.png",
         "imageName": "Kahil Large 1"
       }
     }},
     {"dual-desc-section": {
       "desc1": "Kahil Meats' e-commerce platform efficiently managing hundreds of products, showcasing a commitment to logistical excellence. This system handles product data & stock with precision, demonstrating a dedication to efficient online inventory management.",
       "desc2": "In addition to its logistical capabilities, the project emphasizes Kahil Meats' cultural significance. The website showcases their three annual local business awards, solidifying their reputation as a trusted local butcher known for quality, integrity, & a deep-rooted connection to their heritage."
     }},
    {"dual-image-section": {
      "image1": {
        "imageUrl": "/AppImages/ProjectImages/KahilMeats/KahilDual3.png",
        "imageName": "Kahil Dual 3"
      },
      "image2": {
         "imageUrl": "/AppImages/ProjectImages/KahilMeats/KahilDual4.png",
         "imageName": "Kahil Dual 4"
      }
    }},

    ]
  },
  {
    "title": "BEANSCENE",
    "contribution":"(During Education)",
    "description": {
      "short": "TAFE - Diploma",
      "longCol1": `Bean Scene, A multi level comprehensive restaurant management system with access & authentication levels for customers, staff & managers.`,
      "longCol2": `Accompanied by a mobile application, this system allows staff to allocate dates, layout & times for customers to reserve & redeem in store.`
    },
    'status':'Major Project, Team of 3',
    "techUsed": "C#, ASP.NET MVC, Entity Framework Core & Jquery.  ",
    "links": "Read More",
    "mobileModel":"/AppImages/ProjectImages/BeanScene/BeanSceneModel.webp",
    "readMoreData": [

    
    {
      "youtube-video-section":{
        'url':'https://www.youtube.com/watch?v=v5Fyy7JUu2U'
      },
      
      "dual-desc-section": {
      "desc1": "Bean Scene's restaurant management system represents a significant achievement in software development, implemented using C# ASP.NET. This multi-level system provides access & authentication for customers, staff, & managers, allowing for seamless interaction with the restaurant's internal booking system.",
      "desc2": "By defining three distinct user roles & their corresponding access levels, Bean Scene's system ensures that each stakeholder can effectively manage their responsibilities, leading to a more cohesive & integrated dining experience for all involved."
    }},
    {"dual-desc-section": {
      "desc1": "The system provides four levels of access, each tailored to specific roles and permissions.",
      "desc2": "Customers have access to make online reservations based on available sittings within the system. This access level allows them to book directly without additional privileges."
    }},
    ,
    {"dual-desc-section": {
      "desc1": "Managers have full access to the system, allowing them to handle a wide range of administrative tasks. They can make bookings on behalf of customers, view and manage all reservations, update reservation statuses, schedule sittings, and create employee profiles.",
      "desc2": "Employees have similar permissions to managers, with a few restrictions. While they can manage reservations and view customer bookings, they cannot schedule sittings or create employee profiles."
    }},
    {"dual-desc-section": {
      "desc1": "Members, in addition to making online reservations, benefit from auto-filled user information on booking. They can also view their booking history, making it easy to manage and review past reservations.",
      "desc2": "A mobile app complements the system, offering tailored interfaces for members, employees, and managers. Built with React Native, JWT authentication, and REST APIs, the app displays past reservations with unique QR codes for members. Employees and managers access a QR scanner to verify member codes and view reservation details for efficient management."
    }},
  ]
    
  }
]

export default projectsData;